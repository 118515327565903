import * as jsPDF from "jspdf";
import "jspdf-autotable";
import store from "../store";
import {imgDataCerti} from "../assets/base64/imges-carnet";
export const exportPDF = (pescador) => {
  // var base64Img = require('base64-img');

  const doc = new jsPDF("p", "pt");

  const imgData = imgDataCerti;
   
  doc.addImage(imgData, "PNG", 232, 70, 120, 125);

  doc.setFont("Arial").setFontSize(11).text("GOBERNACIÓN", 290, 220, "center");
  doc
    .setFont("Arial")
    .setFontSize(11)
    .text(200, 235, "Departamento Archipielago de San Andrés");
  doc
    .setFont("Arial")
    .setFontSize(11)
    .text(230, 250, "Providencia y Santa Catalina");
  doc
    .setFont("Arial")
    .setFontSize(11)
    .text(225, 265, "Reserva de Biosfera Seaflower");
  doc.setFont("Arial").setFontSize(11).text(245, 280, "Nit.: 892.400.038 - 2");

  doc.fromHTML(
    '<p style="font-size:16px;">REGISTRO No. <b>' +
      pescador.fishin_card_number +
      "</b>.</p>",
    220,
    290
  );
  doc
    .setFont("Arial")
    .setFontSize(12)
    .text(170, 330, "LA SECRETARÍA DE AGRICULTURA Y PESCA");

  doc.setFont("Arial").setFontSize(12).text(250, 345, "CERTIFICA QUE");

  doc
    .setFont("Arial")
    .setFontSize(12)
    .text(
      "Según el Registro General del Pesca de San Andrés Islas – RGP SAI, Libro de Pescadores Industriales, se encuentra inscrito(a):",
      40,
      380,
      { maxWidth: 520, align: "justify" }
    );
  doc.autoTable({
    startY: 420,
    headStyles: {
      fillColor: [128, 128, 128],
    },
    head: [
      [
        "NOMBRES Y APELLIDOS",
        "TIPO DE DOCUMENTO IDENTIFICACION",
        "No. DOCUMENTO IDENTIFICACION",
        "NACIONALIDAD",
        "TIPO DE OCCRE",
        "No. OCCRE",
      ],
    ],
    body: [
      [
        pescador.name + " " + pescador.lastname,
        pescador.tipo_id.nombre,
        pescador.identification_number,
        pescador.nacionalidad.nombre,
        pescador.occre,
        pescador.no_occre,
      ],
    ],
    theme: "grid",
  });
  doc
    .setFont("Arial")
    .setFontSize(12)
    .text(
      "Como pescador(a) que presta sus servicios a bordo de la embarcación de pesca Comercial Industrial: " +
        pescador.barco.boat_name +
        " - " +
        pescador.barco.registration_number,
      40,
      520,
      { maxWidth: 520, align: "justify" }
    );
  doc
    .setFont("Arial")
    .setFontSize(12)
    .text(pescador.nota, 40, 580, { maxWidth: 520, align: "justify" });
  const expedition_date = new Date(pescador.expedition_date);
  const expiration_date = new Date(pescador.expiration_date);

  var month = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  var mes = month[expedition_date.getMonth()];
  var mes_b = month[expiration_date.getMonth()];
  doc
    .setFont("Arial")
    .setFontSize(12)
    .text(
      `La presente se expide a solicitud del interesado, en San Andrés Isla a los ${
        expedition_date.getDate() + 1
      } días del mes de ${mes} del ${expedition_date.getFullYear()}  y será valido hasta el ${
        expiration_date.getDate() + 1
      } del mes de ${mes_b} del ${expiration_date.getFullYear()}. `,
      40,
      700,
      { maxWidth: 520, align: "justify" }
    );
  doc.line(150, 780, 500, 780);
  doc
    .setFont("Arial")
    .setFontSize(12)
    .text(
      220,
      800,
      `${
        store.state.settings.find((x) => x.nombre == "nombre_secretario").valor
      }`
    );
  doc
    .setFont("Arial")
    .setFontSize(12)
    .text(210, 820, `Secretario(a) de Agricultura y Pesca`);
  doc.save(`Certificadovisual.pdf`);
};
